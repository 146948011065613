// ResetPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Alert } from '@mui/material';
import FormInput from './FormInput';
import FormButton from './FormButton';

function ResetPassword() {
  const [formValues, setFormValues] = useState({
    password: '',
  });
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reset-password/reset/${token}`,
        { password: formValues.password }
      );
      setMessage(response.data); // Show the success message from the server
      setTimeout(() => {
        navigate('/login'); // Redirect to login page after 3 seconds
      }, 3000);
    } catch (err) {
      console.error('Error resetting password:', err); // Add logging
      setMessage('Error resetting password.');
    }
  };

  // Define your input fields
  const fields = [
    { name: 'password', label: 'New Password', type: 'password' },
  ];

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          {fields.map((field, index) => (
            <FormInput
              key={index}
              label={field.label}
              type={field.type}
              value={formValues[field.name]}
              onChange={(e) =>
                setFormValues({ ...formValues, [field.name]: e.target.value })
              }
            />
          ))}
          <FormButton type="submit" sx={{ mt: 2 }}>
            Reset Password
          </FormButton>
        </form>
        {message && (
          <Alert
            severity={message === 'Error resetting password.' ? 'error' : 'success'}
            sx={{ mt: 2, width: '100%' }}
          >
            {message}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default ResetPassword;