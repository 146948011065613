// FormButton.js
import Button from '@mui/material/Button';

const FormButton = ({
  children,
  onClick,
  variant = 'contained',
  color = 'primary',
  fullWidth = true,
  ...props
}) => (
  <Button
    onClick={onClick}
    variant={variant}
    color={color}
    fullWidth={fullWidth}
    {...props}
  >
    {children}
  </Button>
);

export default FormButton;