// LoadingIndicator.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingIndicator = ({ size = 24, sx = {} }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px',
      ...sx, // Spread any additional styles passed via props
    }}
  >
    <CircularProgress size={size} />
  </Box>
);

export default LoadingIndicator;