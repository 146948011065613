// FormInput.js
import TextField from '@mui/material/TextField';

const FormInput = ({ label, type = 'text', value, onChange }) => (
  <TextField
    label={label}
    type={type}
    value={value}
    onChange={onChange}
    required
    fullWidth
    margin="normal"
  />
);

export default FormInput;