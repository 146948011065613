// SignUp.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormInput from './FormInput';
import FormButton from './FormButton';

function SignUp() {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    company: '',
  });
  const [error, setError] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setError('');
    setLoading(true);

    try {
      console.log('[Client] Sending sign-up request with:', formValues);
      await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, formValues);
      setVerificationSent(true);
    } catch (err) {
      console.error('[Client] Error during sign-up:', err);
      setError(err.response?.data || 'Error creating account. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    await handleSubmit(); // Reuse the sign-up function to resend the email
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  // Define your input fields
  const fields = [
    { name: 'firstName', label: 'First Name', type: 'text' },
    { name: 'lastName', label: 'Last Name', type: 'text' },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'password', label: 'Password', type: 'password' },
    { name: 'company', label: 'Company', type: 'text' },
  ];

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 2, boxShadow: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Sign Up
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {!verificationSent ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {fields.map((field, index) => (
            <FormInput
              key={index}
              label={field.label}
              type={field.type}
              value={formValues[field.name]}
              onChange={(e) =>
                setFormValues({ ...formValues, [field.name]: e.target.value })
              }
            />
          ))}
          <FormButton
            type="submit"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? '' : 'Sign Up'}
          </FormButton>
          <FormButton onClick={handleBackToLogin} sx={{ mt: 2 }} variant="text">
            Back to Login
          </FormButton>
        </form>
      ) : (
        <Box>
          <Typography>
            A verification email has been sent to {formValues.email}. Please check your email.
          </Typography>
          <FormButton onClick={handleResendVerification}>
            Resend Verification Email
          </FormButton>
          <FormButton onClick={handleBackToLogin} sx={{ mt: 2 }} variant="text">
            Back to Login
          </FormButton>
        </Box>
      )}
    </Box>
  );
}

export default SignUp;