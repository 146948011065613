import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, TextField, Grid, Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import { useAuth } from '../auth';

function Webhook({ user }) {
  const [url, setUrl] = useState('');
  const [headers, setHeaders] = useState('');
  const [feedback, setFeedback] = useState({ type: '', message: '', response: null });
  const { getAuthHeader } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWebhookConfig = async () => {
      try {
        const storedConfig = sessionStorage.getItem('webhookConfig');
        let config = storedConfig ? JSON.parse(storedConfig) : null;

        if (!config) {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/webhook/configure`, {
            headers: {
              Authorization: getAuthHeader(),
            },
          });
          config = res.data;
          sessionStorage.setItem('webhookConfig', JSON.stringify(config));
        }

        if (config.url) setUrl(config.url);
        if (config.headers) setHeaders(config.headers);
      } catch (err) {
        console.error('Error fetching webhook configuration:', err.message);
      }
    };

    if (user) {
      fetchWebhookConfig();
    }
  }, [user, getAuthHeader]);

  const handleSendTestRequest = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/webhook/proxy`, { url, headers }, {
        headers: {
          Authorization: getAuthHeader(),
        }
      });
      setFeedback({ type: 'success', message: 'Test request sent successfully!', response: res.data });
    } catch (err) {
      setFeedback({ type: 'error', message: `Error: ${err.message}`, response: null });
    }
  };

  const handleSaveConfiguration = async () => {
    const configuration = { url, headers };
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/webhook/configure`, configuration, {
        headers: {
          Authorization: getAuthHeader(),
        }
      });
      sessionStorage.setItem('webhookConfig', JSON.stringify(configuration));
      setFeedback({ type: 'success', message: 'Webhook configuration saved successfully!', response: null });
      setTimeout(() => navigate('/dashboard'), 1500);
    } catch (error) {
      setFeedback({ type: 'error', message: `Error: ${error.message}`, response: null });
    }
  };

  return (
    <AuthWrapper user={user}>
      {user && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h4" gutterBottom>
            Configure Webhook
          </Typography>
          <Typography variant="body1" gutterBottom>
            Use this page to configure a webhook. Enter the URL and headers for the POST webhook.
            The body of the request will contain the lead information. You can test the 
            configuration by sending a test request. The 'Content-Type' header is set automatically.
          </Typography>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Headers (JSON)"
                  value={headers}
                  onChange={(e) => setHeaders(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  placeholder='{"Content-Type": "application/json"}'
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleSendTestRequest} sx={{ mr: 2 }}>
                  Send Test Request
                </Button>
                <Button variant="contained" onClick={handleSaveConfiguration}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {feedback.type && (
            <Alert severity={feedback.type} sx={{ mb: 2 }}>
              <Typography variant="h6">{feedback.type === 'success' ? 'Success' : 'Error'}</Typography>
              <pre>{feedback.message}</pre>
              {feedback.response && (
                <pre>{JSON.stringify(feedback.response, null, 2)}</pre>
              )}
            </Alert>
          )}
        </Box>
      )}
    </AuthWrapper>
  );
}

export default Webhook;