import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

Chart.register(...registerables);

function useChartOptions(chartType, dataType, startDate, data) {
  const isColumnChart = chartType === 'column';
  const isSpending = dataType === 'spending';
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dataset = {
    label: isSpending ? 'Amount Spent ($)' : 'Number of Leads',
    data: data.map(item => (isSpending ? item.totalSpent : item.totalLeads)),
    backgroundColor: isColumnChart ? 'rgba(33, 150, 243, 0.8)' : 'rgba(33, 150, 243, 0.2)',
    borderColor: 'rgba(33, 150, 243, 1)',
    borderWidth: 2,
    fill: !isColumnChart,
    tension: 0.4,
    borderRadius: isColumnChart ? 10 : 0,
    barThickness: isColumnChart ? (isMobile ? 6 : 8) : undefined,
    maxBarThickness: isColumnChart ? 8 : undefined,
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: { unit: 'day' },
        min: startDate,
        max: data.length ? new Date(Math.max(...data.map(d => new Date(d.date)))) : undefined,
        title: {
          display: true,
          text: 'Date',  // X-axis label
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: isSpending ? 'Amount Spent ($)' : 'Leads Purchased',  // Y-axis label
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,  // Turn off aspect ratio management for auto height

    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            // Get the corresponding data item based on the tooltip index
            const itemIndex = tooltipItems[0].dataIndex;
            const chartItem = data[itemIndex];

            // Use the preformatted date from chartData
            return chartItem.formattedDate;
          },
          label: context => {
            const label = `${context.dataset.label}: ${context.raw}`;
            return label;
          }
        },
      },
      legend: { display: false },
    },
  };

  return { datasets: [dataset], options };
}

function LeadsChart({ data, startDate }) {
  const chartRef = useRef(null);
  const [chartType, setChartType] = useState('column');
  const [dataType, setDataType] = useState('leads');

  const { datasets, options } = useChartOptions(chartType, dataType, startDate, data);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById('leadsChart').getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: chartType === 'column' ? 'bar' : 'line',
      data: { labels: data.map(item => item.date), datasets },
      options,
    });

    return () => chartRef.current?.destroy();
  }, [datasets, options, chartType, data]);

  return (
    <Paper sx={{ p: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ToggleButtonGroup
            value={chartType}
            exclusive
            onChange={(e, newType) => setChartType(newType)}
            sx={{ '& .MuiToggleButton-root': { padding: '4px 8px', fontSize: '0.75rem' }, marginRight: 1 }}
            >
            <ToggleButton value="column" >
              <BarChartIcon />
            </ToggleButton>
            <ToggleButton value="line" >
              <ShowChartIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={dataType}
            exclusive
            onChange={(e, newType) => setDataType(newType)}
            sx={{ '& .MuiToggleButton-root': { padding: '4px 8px', fontSize: '0.75rem' }, marginRight: 1 }}
          >
            <ToggleButton value="leads" >
              <PeopleIcon />
            </ToggleButton>
            <ToggleButton value="spending">
              <AttachMoneyIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box sx={{ pr: 1, height: { xs: '300px', md: '400px', lg: '500px' } }}>
        <canvas id="leadsChart"></canvas>
      </Box>
    </Paper>
  );
}

export default LeadsChart;