// src/components/LeadsFiltersComponent.js
import React from 'react';
import { Box, Button, Typography, IconButton, Drawer } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Filters from './Filters';

const LeadsFiltersComponent = ({
  filters,
  handleSearchChange,
  handleStateChange,
  handleAmountChange,
  leadsCount,
  resetFilters,
  drawerOpen,
  setDrawerOpen,
  isMobile,
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, mb: 0 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 0, p: 1 }}>
      <Button variant="contained" onClick={() => setDrawerOpen(true)} sx={{ p: 1, fontSize: '12px' }}>
        Filters
      </Button>
      <Typography variant="h6" sx={{ ml: 0.5 }}>
        {leadsCount} Total Leads
      </Typography>
      <IconButton onClick={resetFilters} sx={{ p: 1 }}>
        <RefreshIcon />
      </IconButton>
    </Box>
    <Drawer
      anchor={isMobile ? 'bottom' : 'left'}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : '200px',
          maxHeight: isMobile ? '80vh' : 'auto',
          padding: '16px',
          overflowY: 'auto',
        },
      }}
    >
      <Filters
        filters={filters}
        handleSearchChange={handleSearchChange}
        handleStateChange={handleStateChange}
        handleAmountChange={handleAmountChange}
      />
    </Drawer>
  </Box>
);

export default LeadsFiltersComponent;