import { createContext, useContext, useState } from 'react';

// Create a context for authentication
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component
export const AuthProvider = ({ user, setUser, children }) => {
  // Local state for purchased leads, settled count, conversion rate, available leads, and available leads count
  const [adminUsers, setAdminUsers] = useState([]);
  const [purchasedLeads, setPurchasedLeads] = useState([]);
  const [settledCount, setSettledCount] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [availableLeads, setAvailableLeads] = useState([]);
  const [availableLeadsCount, setAvailableLeadsCount] = useState(0);

  // Function to get basic auth header
  const getAuthHeader = () => {
    const storedUser = JSON.parse(sessionStorage.getItem('user'));
    if (!storedUser || !storedUser.email || !storedUser.password) {
      throw new Error('User credentials not found in session storage');
    }
    const credentials = `${storedUser.email}:${storedUser.password}`;
    return `Basic ${btoa(credentials)}`;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        adminUsers, 
        setAdminUsers,
        getAuthHeader,
        purchasedLeads,
        setPurchasedLeads,
        settledCount,
        setSettledCount,
        conversionRate,
        setConversionRate,
        availableLeads,
        setAvailableLeads,
        availableLeadsCount,
        setAvailableLeadsCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};