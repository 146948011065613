import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function VerifyEmail() {
  const { token } = useParams();
  const [message, setMessage] = useState('');
  const [verificationAttempted, setVerificationAttempted] = useState(false); // New state to track if the verification was attempted
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        console.log('[Client] Starting email verification for token:', token);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-email/${token}`);
        console.log('[Client] Verification response:', response.data);

        const userData = response.data.user;
        console.log('[Client] Received user data:', userData);

        sessionStorage.setItem('userProfile', JSON.stringify(userData)); // Save the user in session storage
        console.log('session storage updated with user info');


        setMessage('Email verified successfully! Redirecting to login...');
        setVerificationAttempted(true); // Set to true after a successful attempt

        // Optionally, redirect to another page after a short delay
        setTimeout(() => {
          console.log('[Client] Navigating to login page');
          navigate('/login');
        }, 2000); // 2 second delay before redirecting
      } catch (err) {
        console.error('[Client] Verification error:', err);
        setMessage('Invalid or expired token.');
        setVerificationAttempted(true); // Set to true after an unsuccessful attempt as well
      }
    };

    if (!verificationAttempted) {
      verifyEmail();
    }
  }, [token, navigate, verificationAttempted]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>{message}</p>
    </div>
  );
}

export default VerifyEmail;