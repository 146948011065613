import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthWrapper = ({ user, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      console.log('user not found')
      navigate('/login');
    }
  }, [user, navigate]);

  if (!user) {

    return null; // or a loading spinner, or a message
  }

  return <>{children}</>;
};

export default AuthWrapper;