// src/components/ExpandedLead.js
import React from 'react';
import {ExpandedLeadCard} from './LeadCard'; // Adjust the import based on your file structure

const ExpandedLead = ({
  lead,
  isMobile,
  handleBackToList,
  loading,
  handlePurchase,
  response,
  error,
}) => (
  <ExpandedLeadCard
    lead={lead}
    isMobile={isMobile}
    handleBackToList={handleBackToList}
    loading={loading}
    handlePurchase={handlePurchase}
    response={response}
    error={error}
  />
);

export default ExpandedLead;