// paginateLeads now receives leads directly rather than fetching from session storage
export const paginateLeads = (leads, currentPage, leadsPerPage) => {
  const offset = (currentPage - 1) * leadsPerPage;
  return leads.slice(offset, offset + leadsPerPage); // Return the paginated leads
};

// handlePageChange takes leads directly rather than fetching from session storage
export const handlePageChange = (setCurrentPage, setPaginatedLeads, leads, leadsPerPage) => (_, newPage) => {
  setCurrentPage(newPage);
  const paginatedLeads = paginateLeads(leads, newPage, leadsPerPage);
  setPaginatedLeads(paginatedLeads); // Update paginated leads in the component
};

// handleLeadsPerPageChange updates both the number of leads per page and the current paginated data
export const handleLeadsPerPageChange = (setLeadsPerPage, setPaginatedLeads, leads, currentPage) => (event) => {
  const newLeadsPerPage = event.target.value;
  setLeadsPerPage(newLeadsPerPage);
  const paginatedLeads = paginateLeads(leads, currentPage, newLeadsPerPage);
  setPaginatedLeads(paginatedLeads); // Update paginated leads based on new page size
};

// Utility function to format currency
export const formatCurrency = (value) => {
  // Round to two decimal places first
  const roundedValue = Number(value.toFixed(2));
  
  // Determine whether to show 0 or 2 decimal places
  const decimalPart = roundedValue % 1;
  const formattedValue = decimalPart === 0 ? roundedValue.toFixed(0) : roundedValue.toFixed(2);
  
  // Format the number with commas as thousands separators
  return `$${Number(formattedValue).toLocaleString(undefined, { minimumFractionDigits: decimalPart === 0 ? 0 : 2 })}`;
};