import React, { useState } from 'react';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import LeadsList from './components/leadslist/LeadsList';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import MyLeads from './components/MyLeads';
import Webhook from './components/Webhook';
import SignUp from './components/SignUp';
import SignUpFee from './components/SignUpFee';
import PasswordResetRequest from './components/PasswordResetRequest';
import ResetPassword from './components/ResetPassword';
import AdminPanel from './components/AdminPanel';
import VerifyEmail from './components/VerifyEmail';
import NavBar from './components/NavBar';
import BasicNavBar from './components/BasicNavBar';
import InitialDataLoader from './components/InitialDataLoader';
import { Box, CssBaseline } from '@mui/material';
import { AuthProvider } from './auth';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function App() {
  const [user, setUser] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Router>
      <AuthProvider user={user}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#F5F7FA' }}>
          
          {/* Only show authenticated routes with InitialDataLoader */}
          {user ? (
            <InitialDataLoader user={user} setUser={setUser}>
              <NavBar user={user} setUser={setUser} isMdUp={isMdUp} isMobile={isMobile}/>
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Routes>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/leads" element={<LeadsList user={user} setUser={setUser} isMobile={isMobile} />} />
                  <Route path="/dashboard" element={<Dashboard user={user}  isMobile={isMobile} />} />
                  <Route path="/my-leads" element={<MyLeads user={user} isMobile={isMobile} />} />
                  <Route path="/webhook" element={<Webhook user={user} />} />
                  <Route path="/admin" element={<AdminPanel />} />
                  <Route path="/signup-fee" element={<SignUpFee user={user}/>} />
                  <Route path="*" element={<Navigate to="/dashboard" />} /> {/* Redirect undefined routes */}
                </Routes>
              </Box>
            </InitialDataLoader>
          ) : (
            // Render non-authenticated routes without InitialDataLoader
            <>
              <BasicNavBar />
              <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Routes>
                  <Route path="/" element={<Navigate to="/login" />} />
                  <Route path="/login" element={<Login setUser={setUser} />} />
                  <Route path="/signup" element={<SignUp setUser={setUser} />} />
                  <Route path="/reset-password-request" element={<PasswordResetRequest />} />
                  <Route path="/reset-password/:token" element={<ResetPassword />} />
                  <Route path="/verify-email/:token" element={<VerifyEmail />} />
                  <Route path="*" element={<Navigate to="/login" />} /> {/* Redirect undefined routes */}
                </Routes>
              </Box>
            </>
          )}
        </Box>
      </AuthProvider>
    </Router>
  );
}

export default App;