import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Alert,
} from '@mui/material';
import FormInput from './FormInput';

function PasswordResetRequest() {
  const [formValues, setFormValues] = useState({
    email: '',
  });
  const [message, setMessage] = useState(''); // Added message state
  const [messageType, setMessageType] = useState(''); // For success or error
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reset-password/request-reset`,
        { email: formValues.email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setMessageType('success');
      setMessage('Password reset email sent.');
    } catch (err) {
      setMessageType('error');
      setMessage('Error sending password reset email.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  // Define your input fields
  const fields = [{ name: 'email', label: 'Email', type: 'email' }];

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 2 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Request Password Reset
        </Typography>
        <form onSubmit={handleSubmit}>
          {fields.map((field, index) => (
            <FormInput
              key={index}
              label={field.label}
              type={field.type}
              value={formValues[field.name]}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.name]: e.target.value,
                })
              }
            />
          ))}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Send Reset Email'
            )}
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={handleBackToLogin}
            fullWidth
            sx={{ mt: 2 }}
          >
            Back to Login
          </Button>
        </form>
        {message && (
          <Alert severity={messageType} sx={{ mt: 2, p: 0 }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              {messageType === 'success' ? 'Success!' : 'Error'}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {message}
            </Typography>
          </Alert>
        )}
      </Paper>
    </Box>
  );
}

export default PasswordResetRequest;