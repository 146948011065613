// src/components/LeadsListComponent.js
// src/components/LeadsListComponent.js
import React from 'react';
import { Box, Grid } from '@mui/material';
import { SmallLeadCard } from './LeadCard';
import LoadingIndicator from '../LoadingIndicator';

const LeadsListComponent = ({
  leadsToShow,
  onScroll,
  loadingMore,
  handleExpandClick,
}) => (
  <Box
    sx={{
      mt: 1,
      mb: 1,
      pb: {
        xs: '48px', // Add bottom padding on mobile devices
        sm: 0,      // No extra padding on larger devices
      },
      flexGrow: 1,
      overflowY: 'auto',
      height: {
        xs: 'calc(100vh - 128px - 48px)', // Adjust height on mobile devices
        sm: 'calc(100vh - 128px)',        // Original height on larger devices
      },
    }}
    onScroll={onScroll}
  >
    <Grid container spacing={2} sx={{ paddingBottom: '8px' }}>
      {leadsToShow.map((lead) => (
        <Grid item xs={12} key={lead._id}>
          <SmallLeadCard
            lead={lead}
            handleExpandClick={handleExpandClick}
          />
        </Grid>
      ))}
      {loadingMore && (
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      )}
    </Grid>
  </Box>
);

export default LeadsListComponent;