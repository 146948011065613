import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

function BasicNavBar() {
  return (
    <>
    <AppBar position="fixed">
      <Toolbar sx={{ minHeight: '56px', justifyContent: 'center' }}>
        <Typography variant="h6">The Lead Matrix</Typography>
      </Toolbar>
    </AppBar>
     <Toolbar />
     </>

  );
}

export default BasicNavBar;