// DesktopView.js
import React from 'react';
import { Box } from '@mui/material';

const DesktopView = ({ leadsListComponent, expandedLeadComponent }) => (
  <Box sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 52px)' }}>
    <Box sx={{ width: '35%', display: 'flex', flexDirection: 'column', p: 0.5 }}>
      {leadsListComponent}
    </Box>
    <Box sx={{ width: '65%', display: 'flex', flexDirection: 'column', p: 1 }}>
      {expandedLeadComponent}
    </Box>
  </Box>
);

export default DesktopView;